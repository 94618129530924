<template>
  <div class="o-layout o-layout--large">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <h3 class="mb-4">
            {{ SHARED.SupportCenterPage.SideBarNavigation.Title }}
          </h3>

          <recess-card variant="variant1">
            <div class="c-sidebar-navigation d-flex flex-column">
              <router-link
                v-for="(sideBarItem, index) in filteredSideBarNavigation"
                :key="index"
                :to="`/support-center/${sideBarItem.path}`"
                :class="`c-sidebar-navigation__item qa-sidebar-navigation-item-${sideBarItem.path}`"
              >
                {{ sideBarItem.title }}
              </router-link>
            </div>
          </recess-card>
        </div>

        <div class="col-8">
          <transition name="sidebar-navigation" mode="out-in">
            <router-view />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SHARED } from "../../constants/EdumsSharedConstants.json";
import UserService from '../../../shared/services/UserService'

export default {
  Name: "SupportCenter",
  data() {
    return {
      isAdministrator: false,
      userService: new UserService(),
      SHARED,
      sideBarNavigation: [
        {
          title: SHARED.SupportCenterPage.SideBarNavigation.ContactItem,
          path: "contacts",
        },
        {
          title: SHARED.SupportCenterPage.SideBarNavigation.TermsAndConditions,
          path: "terms-and-conditions"
        },
        {
          title: SHARED.SupportCenterPage.SideBarNavigation.UserRoles,
          path: "user-roles"
        },
        {
          title: SHARED.SupportCenterPage.SideBarNavigation.IncompanyItem,
          path: "incompany",
        },
        {
          title: SHARED.SupportCenterPage.SideBarNavigation.WhatsNewItem,
          path: "whats-new",
        },
        {
          title: SHARED.SupportCenterPage.SideBarNavigation.SupportVideos,
          path: "support-videos",
        },
         {
          title: SHARED.SupportCenterPage.SideBarNavigation.Edudex,
          path: "edudex", 
        },

      ],
    };
  },
  computed: {
    filteredSideBarNavigation() {
      if(this.isAdministrator && process.env.VUE_APP_OIDC_CLIENT_ID === 'edums_core') {
        const systemInterruptionLink = {
          title: SHARED.SupportCenterPage.SideBarNavigation.SystemInterruption,
          path: "technische-storing"
        }

        this.sideBarNavigation = [...this.sideBarNavigation, systemInterruptionLink]
      }
      

      return this.sideBarNavigation
    }
  },
  async mounted() {
    this.isAdministrator = await this.userService.isAdministrator() 
  }
};
</script> 